import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { useTranslationContext } from "../context/TranslationContext"

const PageTemplate = ({ data }) => {
  let { page } = data
  const { translationContext } = useTranslationContext()

  return (
    <Layout
      pullUp={page.isNavbarOverlapping}
      hidePromotional={page.hidePromotionalElement}
    >
      <SEO
        metaImage={page.metaImage && page.metaImage.file.url}
        title={page.pageTitle}
        canonicals={translationContext.canonicals}
        description={page.metaDescription?.metaDescription}
      />
      <ContentModules
        pullUp={page.isNavbarOverlapping}
        allModules={page.contentModules}
      />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageContent
    }
  }
`
